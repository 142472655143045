import React, { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  Icon,
  VStack,
  HStack,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Progress,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
} from '@chakra-ui/react';
import {

} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FiFileText, FiBarChart2, FiShield, FiTrendingUp, FiUploadCloud, FiTrash2 } from 'react-icons/fi';
import axios from 'axios';
import Cookies from 'js-cookie';
import Header from './Header';
import MenuBar from './Menu';
import { Bar } from 'react-chartjs-2';

interface Teammates {
  key: string;
  lastModified: string;
  size: number;
  type: string;
  label: string;
}

const Teammates: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputValue, setInputValue] = useState(''); // Input state management
  const { isOpen: isBookkeeperOpen, onOpen: openBookkeeper, onClose: closeBookkeeper } = useDisclosure();
  const { isOpen: isFPnAOpen, onOpen: openFPnA, onClose: closeFPnA } = useDisclosure();
  const [selectedBookkeeperOption, setSelectedBookkeeperOption] = useState('Accounts Payable');
  const [chatMessages, setChatMessages] = useState<string[]>([]);
  const [selectedFPnAOption, setSelectedFPnAOption] = useState('Budget Actuals Analysis');
  const navigate = useNavigate();
  const [reports, setReports] = useState<Teammates[]>([]);
  const [companyId, setCompanyId] = useState('');
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [tags, setTags] = useState('');
  const [timeframe, setTimeframe] = useState('');
  const [step, setStep] = useState(1);
  const [extractedText, setExtractedText] = useState<string | null>(null);
  const [parsedFields, setParsedFields] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prepaidData, setPrepaidData] = useState<any[]>([]);
  const [csvData, setCsvData] = useState<string[][]>([
    ['Month ending ----->', 'Budget Jan-23', 'Actual Jan-23', 'Variance Jan-23'],
    ['SaaS Revenue', '32216.15', '34448', '2231.85'],
    ['Customizations', '3000', '17470', '14470'],
    ['Terminals', '700', '477', '-223'],
    ['Payments', '', '', ''],
    ['Prescriptions', '', '', ''],
    ['Groups Revenue (Institutions)', '10086', '0', '-10086'],
    ['Total revenues', '46002.15', '52395', '6392.85'],
    ['COGS', '21217.73', '27611.87', '6394.15'],
    ['Gross margin', '24784.42', '24783.13', '-1.30'],
    ['Salary & Wages', '22689.39', '45375.13', '22685.74'],
    ['Advertising', '2973.75', '3162', '188.25'],
    ['Bad debt expense', '0', '', '0'],
    ['Bank charges', '1000', '1677', '677'],
    ['Cloud Infrastructure', '0', '', '0'],
    ['Donations', '0', '', '0'],
    ['Grants', '0', '', '0'],
    ['Events', '0', '', '0'],
    ['Insurance', '400', '938', '538'],
    ['Legal and professional fees', '0', '631', '631'],
    ['Management fees', '0', '', '0'],
    ['Marketing', '0', '', '0'],
    ['Meals and entertainment', '0', '1322', '1322'],
    ['Office expense', '250', '1127', '877'],
    ['Recruitment Expense', '0', '', '0'],
    ['Rent', '0', '', '0'],
    ['Software licensing', '3051.43', '6014', '2962.57'],
    ['Sponsorships', '0', '', '0'],
    ['Subcontractor', '27676.85', '21728', '-5948.85'],
    ['Travel & Events', '0', '1426', '1426'],
    ['Total operating expenses', '58041.41', '83400.13', '25358.71'],
    ['EBITDA', '-33256.99', '-58617', '-25360.01'],
    ['Depreciation and amortization', '100', '17', '-83'],
    ['EBIT', '-33356.99', '-58634', '-25277.01'],
    ['Other expenses / (income)', '-1200', '405', '1605'],
    ['Grants', '0', '425', '425'],
    ['Interest expense / (income)', '0', '', '0'],
    ['Income tax expense', '0', '0', '0'],
    ['Net income / (loss)', '-34556.99', '-57804', '-23247.01'],
    ['Total Expenses and COGS', '79259.14', '111012', '31752.86']
  ]);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const pyDomain = 'http://localhost:5000'
  const [loadingStep, setLoadingStep] = useState(false);

  const fetchPrepaidSummary = async () => {
    try {
      setLoading(true);
      //const response = await axios.get(${pyDomain}/prepaid-exp-summary);
      //const { latest_invoice, extracted_fields } = response.data;

      // Format the summary message for chat display
      const summaryMessage = `
      Vendor: Hubspot
      Description: Software Subscription
      Amount: 7,195.07 USD
      Period: 09-30-2024 to 09-29-2024
      Deferred for Sept: 599 USD
      Extracted Fields: {
        "Invoice Number": "22286737",
        "Due Date": "2024-09-30",
        "Tax": "0 USD",
        "Total with Tax": "7,195.07 USD"
      Summary: The invoice from HubSpot confirms a payment of $7,195.07 by OmniSync Incorporated on September 30, 2024. It covers subscriptions for Sales Hub Professional, Service Hub Professional, and Content Hub Starter for one year, with multiple discounts applied
      }
    `;

      // Add the summary message to chat
      setChatMessages((prev) => [...prev, `Bot: ${summaryMessage}`]);
    } catch (error) {
      console.error('Error fetching prepaid summary:', error);
      setChatMessages((prev) => [...prev, 'Bot: Failed to fetch prepaid summary.']);
    } finally {
      setLoading(false);
    }
  };

  // Fetch Prepaid Expenses data from the backend
  const fetchPrepaidExpenses = async () => {
    try {
      setLoading(true);
      const response = await axios.get('http://localhost:5000/prepaid-expenses');
      setPrepaidData(response.data);
    } catch (error) {
      console.error('Error fetching prepaid expenses:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleBookkeeperOptionChange = (option: string) => {
    setSelectedBookkeeperOption(option);
    if (option === 'Prepaid Expenses') fetchPrepaidExpenses();
  };

  // Handle file change for invoice upload
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files ? event.target.files[0] : null);
  };

  // Upload invoice to backend
  const handleUpload = async (selectedFile: File) => {
    try {
      setLoading(true); // Start loading indicator
      setChatMessages([]);
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Step 1: Upload the invoice and extract raw text
      const uploadResponse = await axios.post('http://127.0.0.1:5000/upload-invoice', formData);
      const { invoice_text } = uploadResponse.data;
      setExtractedText(invoice_text);

      // Add raw text to the chat window
      setChatMessages((prev) => [
        ...prev,
        'Bot: Invoice uploaded successfully. Extracted Text:\n${invoice_text}'
      ]);

      // Step 2: Call fetchPrepaidSummary to get summary from backend
      await fetchPrepaidSummary(); // Trigger summary retrieval

    } catch (error) {
      console.error('Error during upload or extraction:', error);
      setChatMessages((prev) => [
        ...prev,
        'Bot: An error occurred while processing the invoice. Please try again.'
      ]);
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  const handleNext = () => {
    if (step < 4) {
      setLoadingStep(true);  // Set loading to true

      // Simulate a loading delay
      setTimeout(() => {
        setLoadingStep(false);  // Stop loading after delay
        setStep(step + 1);  // Move to the next step
      }, 2000);  // 2000ms delay (2 seconds)
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setLoadingStep(true);  // Set loading to true

      // Simulate a loading delay
      setTimeout(() => {
        setLoadingStep(false);  // Stop loading after delay
        setStep(step - 1);  // Move to the previous step
      }, 2000);  // 2000ms delay (2 seconds)
    }
  };

  useEffect(() => {
    setChatMessages([]);
    console.log('Chat messages on mount:', chatMessages);
    setLoading(false); // Verify if it's an empty array
    },[]);
  const varianceData = {
    labels: [
      "Revenue", "Operating Expenses", "Cost of Goods Sold", "Other"
    ],
    datasets: [
      {
        label: 'Total Variance',
        data: [3873.23, 20375.99, 5434.57, 300.67],
        backgroundColor: 'skyblue',
      },
    ],
  };

  const varianceOptions = {
    indexAxis: 'y' as const,  // Explicitly set the type to 'y'
    scales: {
      x: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Variances by Category',
      },
    },
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Text>Loading...</Text>
      </Flex>
    );
  }

  const handleLaunchClick = () => {
    onOpen(); // Opens the modal when Launch is clicked
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Text>Loading...</Text>
      </Flex>
    );
  }

  const teammateData = [
    {
      icon: FiFileText,
      title: 'Bookkeeper Teammate',
      description: ['Manage invoices', 'Reconcile transactions', 'Prepare month-end reports'],
      onLaunch: openBookkeeper,
    },
    {
      icon: FiBarChart2,
      title: 'FP&A Analyst Teammate',
      description: ['Forecast cash flow', 'Analyze expenditure', 'Budget Actuals Analysis'],
      onLaunch: openFPnA,
    },
    {
      icon: FiShield,
      title: 'Controller Teammate',
      description: ['Identify risks', 'Monitor regulatory changes', 'Risk management plans'],
    },
    {
      icon: FiTrendingUp,
      title: 'Strategic Planning Teammate',
      description: ['Launch new product', 'Market expansion', 'Mergers and acquisitions'],
    },
  ];

  return (
    <Flex minHeight="100vh" direction="column" bg="gray.100" color="black" overflow="hidden">
      <Header />
      <Flex flex="1" direction={{ base: 'column', md: 'row' }} overflow="hidden">
        <MenuBar />
        <Box flex="1" p="4">
          <Heading fontSize="2xl" mb="4">Fincrafters</Heading>
          <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
            {teammateData.map((teammate, index) => (
              <GridItem
                key={index}
                w="100%"
                h="auto"
                bg="white"
                borderRadius="md"
                boxShadow="md"
                p="4"
                transition="transform 0.2s, box-shadow 0.2s"
                _hover={{ transform: 'scale(1.05)', boxShadow: 'lg' }}
              >
                <VStack spacing={3}>
                  <Icon as={teammate.icon} boxSize={8} />
                  <Heading fontSize="lg">{teammate.title}</Heading>
                  {teammate.description.map((desc, idx) => (
                    <Text key={idx}>{desc}</Text>
                  ))}
                  <Button colorScheme="blue" size="sm" onClick={teammate.onLaunch}>
                    Launch
                  </Button>
                </VStack>
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Flex>

      <Modal isOpen={isBookkeeperOpen} onClose={closeBookkeeper} size="6xl">
        <ModalOverlay />
        <ModalContent width="70%" height="70%">
          <ModalHeader>Bookkeeper Teammate</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding="0" height="calc(100% - 64px)">
            <Flex height="100%">
              {/* Left Side: Dropdown and Upload Button */}
              <Box
                width="50%"
                borderRight="1px solid lightgray"
                overflowY="auto"
                overflowX="hidden"
                maxHeight="100%"
              >
                <Flex align="center" mb={4} gap={4}>
                  <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" variant="outline">
                      {selectedBookkeeperOption}
                    </MenuButton>
                    <MenuList>
                      {['Accounts Payable', 'Accounts Receivable', 'Prepaid Expenses', 'Fixed Assets Depreciation'].map(
                        (option) => (
                          <MenuItem key={option} onClick={() => handleBookkeeperOptionChange(option)}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </MenuList>
                  </Menu>

                  <Button
                    size="sm"
                    onClick={() => document.getElementById('file-upload')?.click()}
                  >
                    + Upload Invoice
                  </Button>

                  {/* Hidden File Input */}
                  <input
                    id="file-upload"
                    type="file"
                    accept=".pdf,.csv,.xlsx"
                    onChange={async (event) => {
                      const selectedFile = event.target.files ? event.target.files[0] : null;
                      if (selectedFile) {
                        setFile(selectedFile); // Save the file in state
                        await handleUpload(selectedFile); // Trigger upload function immediately
                      }
                    }}
                    style={{ display: 'none' }} // Ensure input is completely hidden
                  />
                </Flex>

                <Tabs variant="enclosed" isFitted mt={4}>
                  <TabList>
                    <Tab>Reports</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel padding="0">
                      {selectedBookkeeperOption === 'Prepaid Expenses' && loading ? (
                        <Spinner size="lg" />
                      ) : (
                        <Table variant="simple" width="100%">
                          <Thead>
                            <Tr>
                              {prepaidData.length > 0 &&
                                Object.keys(prepaidData[0]).map((key) => <Th key={key}>{key}</Th>)}
                            </Tr>
                          </Thead>
                          <Tbody>
                            {prepaidData.map((row, rowIndex) => (
                              <Tr key={rowIndex}>
                                {Object.values(row).map((cell, cellIndex) => (
                                  <Td key={cellIndex}>{String(cell) || 'N/A'}</Td>
                                ))}
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>

              {/* Right Side: Chat Interface */}
              <Box
                width="50%"
                p={4}
                display="flex"
                flexDirection="column"
                overflowY="auto"
                maxHeight="100%"
              >
                <Heading fontSize="lg" mb={4}>
                  Chat with Bookkeeper Bot
                </Heading>
                <VStack spacing={4} flex="1" overflowY="auto" align="start" bg="gray.50" p={4} borderRadius="md">
                  {chatMessages.length === 0 ? (
                    <Text bg="white" p={2} borderRadius="md" alignSelf="flex-start">
                      No messages yet. Start by uploading an invoice.
                    </Text>
                  ) : (
                    chatMessages.map((message, index) => (
                      <Text key={index} bg="white" p={2} borderRadius="md" alignSelf="flex-start">
                        {message}
                      </Text>
                    ))
                  )}
                </VStack>

                {/* Chat Input */}
                <HStack mt={4}>
                  <Input
                    placeholder="Type your message..."
                    variant="outline"
                    size="md"
                    flex="1"
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
                  />
                  <Button colorScheme="blue" size="md" onClick={() => console.log('Send:', inputValue)}>
                    Send
                  </Button>
                </HStack>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={closeBookkeeper}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* FP&A Teammate Modal */}
      <Modal isOpen={isFPnAOpen} onClose={closeFPnA} size="6xl">
        <ModalOverlay />
        <ModalContent width="70%" height="70%">
          <ModalHeader>FP&A Analyst Teammate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" variant="outline">
                {selectedFPnAOption}
              </MenuButton>
              <MenuList>
                {['Budget Actuals Analysis', 'Cash Flow Analysis'].map((option) => (
                  <MenuItem key={option} onClick={() => setSelectedFPnAOption(option)}>
                    {option}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>

            <Tabs variant="enclosed" isFitted mt={4}>
              <TabList>
                <Tab>Raw Data</Tab>
                <Tab>Reports</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <p>Display raw FP&A data here...</p>
                </TabPanel>
                <TabPanel>
                  <Flex justify="center" align="center" height="100%">
                    <Button colorScheme="blue" size="lg">
                      Run
                    </Button>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={closeFPnA}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Teammates;