import React, { useState, useEffect } from 'react';
import { Box, Button, Input, VStack, HStack, Text, Flex, Avatar, Tab, TabList, TabPanels, TabPanel, Tabs, Divider, Spinner, FormControl, FormLabel, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import MenuBar from './Menu';
import Cookies from 'js-cookie';
import FincraftBot from './assets/fincraft-img-256.png';

const ProfileSettings: React.FC = () => {
  const [profile, setProfile] = useState({ firstName: '', lastName: '', email: '', roles: [] });
  const [editableProfile, setEditableProfile] = useState({ firstName: '', lastName: '' });
  const [newProfileImage, setNewProfileImage] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  useEffect(() => {
    const fetchProfile = async () => {
      const token = Cookies.get('access_token');
      if (!token) {
        navigate('/login');
        return;
      }
      try {
        const response = await axios.get(`${apiDomain}/auth/current-user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProfile({
          firstName: response.data.given_name || '',
          lastName: response.data.family_name || '',
          email: response.data.email || '',
          roles: response.data.roles || [],
        });
        setEditableProfile({
          firstName: response.data.given_name || '',
          lastName: response.data.family_name || '',
        });
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, [navigate, apiDomain]);

  const handleProfileUpdate = async () => {
    const token = Cookies.get('access_token');
    if (!token) {
      navigate('/login');
      return;
    }
    setLoading(true);
    console.log('Updating profile with:', editableProfile);
    try {
      await axios.patch(`${apiDomain}/auth/update-user`, { firstName: editableProfile.firstName, lastName: editableProfile.lastName }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProfile(prevProfile => ({
        ...prevProfile,
        ...editableProfile,
      }));
      if (newProfileImage) {
        // handle image upload separately if needed
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex minHeight="100vh" direction="column" bg="gray.100" color="black" overflow="hidden">
      <Header />
      <Flex flex="1" direction={{ base: 'column', md: 'row' }} overflow="hidden">
        <MenuBar />
        <Box flex="1" p="4">
          <Text fontSize="2xl" mb="4">Personal Settings</Text>
          <Tabs variant="enclosed" size="sm">
            <TabList mb="0">
              <Tab>Profile</Tab>
              <Tab>Account</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box bg="white" p="4" borderRadius="md" boxShadow="md" w="100%">
                  <VStack align="start" spacing="4" w="100%">
                    <HStack spacing="4" w="100%">
                      <Avatar size="xl" name={profile.firstName + ' ' + profile.lastName} src={FincraftBot} />
                      <VStack align="start">
                        <Text fontSize="2xl">{profile.firstName} {profile.lastName}</Text>
                        <Text>{profile.email}</Text>
                        <Text>{profile.roles.join(', ')}</Text>
                      </VStack>
                    </HStack>
                    <FormControl id="profile-picture">
                      <FormLabel>Change Profile Picture</FormLabel>
                      <Input type="file" onChange={(e) => setNewProfileImage(e.target.files ? e.target.files[0] : null)} />
                    </FormControl>
                    <FormControl id="first-name">
                      <FormLabel>First Name</FormLabel>
                      <Input
                        placeholder="First Name"
                        value={editableProfile.firstName}
                        onChange={(e) => setEditableProfile({ ...editableProfile, firstName: e.target.value })}
                        size="sm"
                      />
                    </FormControl>
                    <FormControl id="last-name">
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        placeholder="Last Name"
                        value={editableProfile.lastName}
                        onChange={(e) => setEditableProfile({ ...editableProfile, lastName: e.target.value })}
                        size="sm"
                      />
                    </FormControl>
                    <Button colorScheme="blue" onClick={handleProfileUpdate}>Save</Button>
                  </VStack>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box bg="white" p="4" borderRadius="md" boxShadow="md" w="100%">
                  <VStack align="start" spacing="4" w="100%">
                    <Text fontSize="lg" fontWeight="bold">Business Profile Setup</Text>
                    <Link as={RouterLink} to="/profile" color="blue.500">Go to Business Profile Setup</Link>
                    <Divider />
                    <Text fontSize="lg" fontWeight="bold">Workspaces</Text>
                    <Divider />
                    <Text fontSize="lg" fontWeight="bold">API Credentials</Text>
                    <Divider />
                    <Text fontSize="lg" fontWeight="bold">Team Members</Text>
                    <Divider />
                    <Text fontSize="lg" fontWeight="bold">Plans and Billing</Text>
                    <Divider />
                  </VStack>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
      {loading && (
        <Flex position="fixed" top="0" left="0" width="100%" height="100%" alignItems="center" justifyContent="center" bg="rgba(0,0,0,0.5)" zIndex="1000">
          <Spinner size="xl" />
        </Flex>
      )}
    </Flex>
  );
};

export default ProfileSettings;
