import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  IconButton,
  HStack,
  Td,
  Tr,
  Text,
  Table,
  Thead,
  Tbody,
  Th,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  useToast,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Header from './Header';
import Menu from './Menu';

interface User {
  name: string;
  email: string;
  roles: string[];
  status: string;
  last_login?: string;
  id: string;
}

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [newRole, setNewRole] = useState<string>('');
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure(); // Add user modal
  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState('Admin');
  const [companyId, setCompanyId] = useState<string | null>(null);
  const toast = useToast();
  const navigate = useNavigate();
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  const fetchProfile = async () => {
    const token = Cookies.get('access_token');
    if (!token) {
      navigate('/login');
      return;
    }
    try {
      const response = await axios.get(`${apiDomain}/auth/current-user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const user = response.data;
      console.log("Profile fetched:", user);

      // Extract company_id from user_metadata
      const companyId = user?.user_metadata?.company_id;

      if (companyId) {
        console.log("Company ID:", companyId);
        setCompanyId(companyId); // Store the company_id for later use
        fetchUsers(user.user_id);  // Send the user_id to the backend as a path parameter
      } else {
        setError('Company ID not found');
      }
    } catch (err) {
      handleError(err, 'Error fetching profile');
      navigate('/login');
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [apiDomain, navigate]);

  const fetchUsers = async (userId: string) => {
    const token = Cookies.get('access_token');
    if (!token) {
      setError('No token found');
      return;
    }

    try {
      const response = await axios.get(`${apiDomain}/auth/company-users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Fetched users:", response.data);
      setUsers(response.data);
    } catch (err) {
      handleError(err, 'Error fetching users');
    }
  };

  const handleError = (err: any, defaultMessage: string) => {
    if (axios.isAxiosError(err)) {
      console.error(defaultMessage, err.response?.data || err.message);
      setError(err.response?.data?.message || defaultMessage);
    } else if (err instanceof Error) {
      console.error(defaultMessage, err.message);
      setError(err.message);
    } else {
      console.error('Unexpected error', err);
      setError('Unexpected error occurred');
    }
  };

  const handleDeleteUser = (user: User) => {
    setSelectedUser(user);
    onDeleteOpen();
  };

  const handleEditUser = (user: User) => {
    console.log("Edit button clicked for user:", user); // Log when the edit button is clicked
    setSelectedUser(user);
    setNewRole(user.roles[0] || ''); // Assuming only one role per user
    onEditOpen();
  };

  const handleUpdateRole = async () => {
    if (!selectedUser) {
      console.error('Selected user is null');
      return;
    }

    console.log("Updating role for user ID:", selectedUser.id); // Log the selected user's ID before making the API call

    const token = Cookies.get('access_token');
    if (!token) {
      setError('No token found');
      return;
    }

    try {
      await axios.post(
        `${apiDomain}/auth/update-role/${selectedUser.id}`, // Use selectedUser.id directly in the URL
        {
          role: newRole, // Send only the role in the request body
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Ensure the token is valid
          },
        }
      );
      console.log("Role updated successfully"); // Log when the role update is successful
      setUsers(users.map((user) => (user.id === selectedUser.id ? { ...user, roles: [newRole] } : user)));
      onEditClose();
    } catch (err) {
      handleError(err, 'Error updating user role');
    }
  };

  const handleConfirmDeleteUser = async () => {
    if (!selectedUser) return;

    const token = Cookies.get('access_token');
    if (!token) {
      setError('No token found');
      return;
    }

    try {
      await axios.delete(`${apiDomain}/auth/users/${selectedUser.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(users.filter((user) => user.id !== selectedUser.id));
      onDeleteClose();
    } catch (err) {
      handleError(err, 'Error deleting user');
    }
  };

  const handleSaveUser = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = Cookies.get('access_token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      await axios.post(
        `${apiDomain}/auth/otp`,
        {
          user_name: newUserName,
          user_email: newUserEmail,
          company_id: companyId,
          role: newUserRole,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers([
        ...users,
        {
          name: newUserName,
          email: newUserEmail,
          roles: [newUserRole],
          status: 'Active',
          id: Math.random().toString(36).substr(2, 9),
          last_login: '',
        },
      ]);
      toast({
        title: 'User added successfully.',
        description: 'An email has been sent to the user to set up their account.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onAddClose();
      resetForm();
    } catch (err) {
      handleError(err, 'Error generating login URL');
    }
  };

  const resetForm = () => {
    setNewUserName('');
    setNewUserEmail('');
    setNewUserRole('Admin');
  };

  if (error) {
    return (
      <Box>
        <Text color="red.500">Error: {error}</Text>
      </Box>
    );
  }

  if (!users || users.length === 0) {
    return (
      <Box>
        <Text>Loading users...</Text> {/* Add this loading message */}
      </Box>
    );
  }

  return (
    <Flex minHeight="100vh" direction="column" bg="gray.100">
      <Header />
      <Flex flex="1">
        <Menu />
        <Box flex="1" p="8">
          <Box mb="8">
            <Text as="h1" fontSize="3xl" fontWeight="bold">
              Manage Users
            </Text>
            <Button colorScheme="blue" onClick={onAddOpen} mt={4}>
              Add User
            </Button>
          </Box>
          <Box bg="white" shadow="md" borderRadius="md" p="4" mb="8">
            <Table width="100%">
              <Thead>
                <Tr>
                  <Th textAlign="left">Name</Th>
                  <Th textAlign="left">Email</Th>
                  <Th textAlign="left">Role</Th>
                  <Th textAlign="left">Status</Th>
                  <Th textAlign="left">Last Login</Th>
                  <Th textAlign="left">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map((user) => (
                  <Tr key={user.id}>
                    <Td>{user.name}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.roles.join(', ')}</Td>
                    <Td>{user.status}</Td>
                    <Td>{user.last_login ? new Date(user.last_login).toLocaleDateString() : 'Never'}</Td>
                    <Td>
                      <HStack spacing="4">
                        <IconButton
                          aria-label="Edit User"
                          icon={<EditIcon />}
                          onClick={() => handleEditUser(user)}
                        />
                        <IconButton
                          aria-label="Delete User"
                          icon={<DeleteIcon />}
                          onClick={() => handleDeleteUser(user)}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Flex justify="flex-end" p="4">
              <Text>{users.length} users</Text>
            </Flex>
          </Box>
        </Box>
      </Flex>

      {/* Add User Modal */}
      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text mb="2">Name:</Text>
              <Input placeholder="Name" value={newUserName} onChange={(e) => setNewUserName(e.target.value)} />
            </Box>
            <Box mt="4">
              <Text mb="2">Email:</Text>
              <Input placeholder="Email" value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} />
            </Box>
            <Box mt="4">
              <Text mb="2">Role:</Text>
              <Select
                placeholder="Select role"
                value={newUserRole}
                onChange={(e) => setNewUserRole(e.target.value)}
              >
                <option value="Admin">Admin</option>
                <option value="Analyst">Analyst</option>
                <option value="CFO-Service">CFO-Service</option>
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveUser}>
              Add
            </Button>
            <Button variant="ghost" onClick={onAddClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Edit Role Modal */}
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit User Role</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select value={newRole} onChange={(e) => setNewRole(e.target.value)}>
              <option value="Admin">Admin</option>
              <option value="Analyst">Analyst</option>
              <option value="CFO-Service">CFO-Service</option>
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleUpdateRole}>
              Save
            </Button>
            <Button variant="ghost" onClick={onEditClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this user? This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleConfirmDeleteUser}>
              Delete
            </Button>
            <Button variant="ghost" onClick={onDeleteClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default UserManagement;
