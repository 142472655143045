import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SelectApi = () => {
  const [selectedApi, setSelectedApi] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleApiSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedApi(e.target.value);
  };

  const handleRunEtl = async () => {
    if (!selectedApi) {
      setError('Please select a QuickBooks API.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get(`http://localhost:8081/quickbooks/etl`, {
        params: { api: selectedApi },
        withCredentials: true
      });
      setIsLoading(false);
      navigate(`/${selectedApi}`); // Navigate to the specific API page after successful ETL
    } catch (err: any) {
      setError(err.response?.data.message || err.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-6 text-center">Select QuickBooks API</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Select QuickBooks API:</label>
          <select value={selectedApi} onChange={handleApiSelection} className="border rounded px-3 py-2 mt-2 w-full">
            <option value="">-- Select an API --</option>
            <option value="accounts">Accounts</option>
            <option value="invoices">Invoices</option>
            <option value="customers">Customers</option>
            {/* Add more API options as needed */}
          </select>
        </div>
        <button
          onClick={handleRunEtl}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
          disabled={isLoading}
        >
          {isLoading ? 'Running ETL...' : 'Run ETL'}
        </button>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
            <span className="block sm:inline">{error}</span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setError(null)}>
              <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <title>Close</title>
                <path d="M14.348 5.652a1 1 0 0 0-1.414 0L10 8.586 7.066 5.652a1 1 0 0 0-1.414 1.414L8.586 10l-2.934 2.934a1 1 0 1 0 1.414 1.414L10 11.414l2.934-2.934a1 1 0 0 0 0-1.414z" />
              </svg>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectApi;
