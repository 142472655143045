import React, { createContext, useContext, useState, ReactNode } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

interface AuthContextType {
  authState: string | null;
  login: (email: string, password: string) => Promise<string>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [authState, setAuthState] = useState<string | null>(Cookies.get('access_token') || null);

  const login = async (email: string, password: string): Promise<string> => {
    const apiDomain = process.env.REACT_APP_API_DOMAIN!;
    try {
      const response = await axios.post(`${apiDomain}/auth/token`, {
        username: email,
        password,
      });
      const { access_token, redirectUrl } = response.data;
      Cookies.set('access_token', access_token, { path: '/', domain: 'app.fincraft.ai', secure: true, sameSite: 'strict' });
      setAuthState(access_token); // Use setAuthState here to update the context state
      return redirectUrl;
    } catch (err) {
      console.error('Login error:', err);
      throw err;
    }
  };

  const logout = () => {
    Cookies.remove('access_token', { path: '/', domain: 'app.fincraft.ai' });
    setAuthState(null);
  };

  return (
    <AuthContext.Provider value={{ authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
