import React, { useState, useEffect } from 'react';
import {
  Box, Flex, Input, IconButton, Avatar, Popover, PopoverTrigger, PopoverContent,
  PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, Menu, MenuButton,
  MenuList, MenuItem, Text
} from '@chakra-ui/react';
import { SearchIcon, BellIcon, QuestionIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './auth/AuthContext'; // Updated import
import Logo from './assets/logo.png';

const Header = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [user, setUser] = useState<any>(null);
  const { authState, logout } = useAuth(); // Use AuthContext
  const navigate = useNavigate();

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  useEffect(() => {
    if (!authState) {
      navigate('/login');
    } else {
      fetchUserData();
    }
  }, [authState, navigate]);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${apiDomain}/auth/current-user`, {
        headers: { Authorization: `Bearer ${authState}` },
      });
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      navigate('/login');
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = () => {
    console.log(`Searching for: ${searchQuery}`);
    // Implement search functionality
  };

  const alerts = [
    'Alert 1: You have a new message.',
    'Alert 2: Your profile has been updated.',
    'Alert 3: New comment on your post.',
    'Alert 4: Your subscription is about to expire.',
    'Alert 5: You have a new connection request.',
  ];

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <Box bg="white" px="4" py="2" shadow="md">
      <Flex justify="space-between" align="center">
        <Box>
          <img src={Logo} alt="Logo" style={{ height: '40px' }} />
        </Box>
        <Flex align="center">
          <Input
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyPress={(e) => e.key === 'Enter' && handleSearchSubmit()}
            maxWidth="300px"
            mr="4"
          />
          <IconButton
            aria-label="Search"
            icon={<SearchIcon />}
            onClick={handleSearchSubmit}
            mr="4"
          />
          <IconButton
            aria-label="Knowledge Base"
            icon={<QuestionIcon />}
            onClick={() => navigate('/knowledge-base')}
            mr="4"
          />
          <Popover>
            <PopoverTrigger>
              <IconButton aria-label="Alerts" icon={<BellIcon />} mr="4" />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Alerts</PopoverHeader>
              <PopoverBody>
                {alerts.map((alert, index) => (
                  <Box key={index} mb="2">
                    {alert}
                  </Box>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Menu>
            <MenuButton>
              <Avatar name={user?.name || 'User'} src={user?.picture || '/path/to/profile-image.jpg'} />
            </MenuButton>
            <MenuList>
              {user && (
                <Box px="4" py="2" borderBottom="1px solid #e2e8f0">
                  <Text fontWeight="bold">Hello, {user.name}</Text>
                </Box>
              )}
              <MenuItem onClick={() => navigate('/settings')}>View Profile</MenuItem>
              <MenuItem onClick={() => navigate('/settings')}>Account Settings</MenuItem>
              <MenuItem onClick={() => navigate('/knowledge-base')}>Help & Support</MenuItem>
              <MenuItem onClick={() => navigate('/coming-soon')}>Community</MenuItem>
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
