import React, { useEffect, useState } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react';
import axios from 'axios';
import Menu from './Menu';
import Header from './Header';

interface Update {
  version: string;
  changes: string;
  date: string;
}

const ProductUpdates = () => {
  const [updates, setUpdates] = useState<Update[]>([]);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        const response = await axios.get(`${apiDomain}/product-updates`);
        setUpdates(response.data);
      } catch (error) {
        console.error('Error fetching updates:', error);
      }
    };

    fetchUpdates();
  }, [apiDomain]);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" bg="gray.100">
      <Header />
      <Box display="flex" flex="1">
        <Menu />
        <Box flex="1" p="8">
          <Text fontSize="2xl" fontWeight="bold" mb="8">Fincraft Product Updates</Text>
          <Box bg="white" p="8" shadow="md" borderRadius="md">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Version</Th>
                  <Th>Changes</Th>
                  <Th>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {updates.map((update, index) => (
                  <Tr key={index}>
                    <Td>{update.version}</Td>
                    <Td>{update.changes}</Td>
                    <Td>{update.date}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductUpdates;
