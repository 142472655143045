import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from './auth/AuthContext';
import CustomLogin from './CustomLogin';
import NewUser from './NewUser';
import Register from './Register';
import ClientPortal from './ClientPortal';
import UserManagement from './UserManagement';
import HomePage from './HomePage';
import Connectors from './Connectors';
import SelectApi from './SelectApi';
import Dashboard from './Dashboard';
import ChatInterface from './ChatInterface';
import BusinessProfile from './BusinessProfile';
import KnowledgeBaseProfile from './KnowledgeBase';
import ProductUpdates from './ProductUpdates';
import ComingSoon from './ComingSoon';
import Settings from './ProfilePage';
import Teammates from './Teammates'
import theme from './theme';
import Cookies from 'js-cookie'; // Add the import statement here

const App = () => {
  useEffect(() => {
    const token = Cookies.get('access_token'); // Use Cookies.get instead of localStorage.getItem
    if (!token && window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
  }, []);
  
  return (
    
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<CustomLogin />} />
            <Route path="/new-user" element={<NewUser />} />
            <Route path="/register" element={<Register />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/client-portal" element={<ClientPortal />} />
            <Route path="/users" element={<UserManagement />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/connectors" element={<Connectors />} />
            <Route path="/select-api" element={<SelectApi />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/chat" element={<ChatInterface />} />
            <Route path="/profile" element={<BusinessProfile />} />
            <Route path="/knowledge-base" element={<KnowledgeBaseProfile />} />
            <Route path="/product-updates" element={<ProductUpdates />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/teammates" element={<Teammates />} />
          </Routes>
        </AuthProvider>
      </Router>
    
  );
};

export default App;
