import React, { useState } from 'react';
import { Box, Flex, VStack, Text, Image, Input, Grid, GridItem, Button } from '@chakra-ui/react';
import Menu from './Menu';
import Header from './Header';
import axios from 'axios';
import Cookies from 'js-cookie';

const KnowledgeBase = () => {
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  const handleVideoClick = async (videoId: string) => {
    const token = Cookies.get('access_token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const response = await axios.get(`${apiDomain}/kb/video/${videoId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedVideo(response.data.url);
    } catch (error) {
      console.error('Error fetching video:', error);
    }
  };

  return (
    <Box display="flex" minHeight="100vh" bg="gray.100">
      <Menu />
      <Box flex="1">
        <Header />
        <Box p="8">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb="8">
            <Text fontSize="2xl" fontWeight="bold">Fincraft Knowledge Base</Text>
            <Box display="flex" alignItems="center">
              <Input placeholder="Search for help articles" width="300px" mr="4" />
              <Image borderRadius="full" boxSize="40px" src="/path/to/profile-image.jpg" alt="Profile Image" />
              <Box ml="2">
                <Text fontWeight="bold">Jennifer Smith</Text>
                <Text fontSize="sm">Team Member</Text>
              </Box>
            </Box>
          </Box>
          <Text fontSize="lg" mb="4">Browse help articles or search for answers</Text>
          <Input placeholder="Search for help articles" mb="8" />

          <Grid templateColumns="repeat(3, 1fr)" gap="6">
            <GridItem>
              <Box bg="white" shadow="md" borderRadius="md" overflow="hidden">
                <Image src="/path/to/tutorial-image.jpg" alt="Tutorial" />
                <Box p="4">
                  <Text fontSize="lg" fontWeight="bold">How to set up custom domain</Text>
                  <Text mb="4">Watch this video to learn how to set up a custom domain in our system</Text>
                  <Button variant="link" colorScheme="blue" onClick={() => handleVideoClick('video1')}>Watch Tutorial</Button>
                </Box>
              </Box>
            </GridItem>
            <GridItem>
              <Box bg="white" shadow="md" borderRadius="md" overflow="hidden">
                <Image src="/path/to/tutorial-image.jpg" alt="Tutorial" />
                <Box p="4">
                  <Text fontSize="lg" fontWeight="bold">Getting started with Fincraft</Text>
                  <Text mb="4">Watch this video to learn how to get started with Fincraft</Text>
                  <Button variant="link" colorScheme="blue" onClick={() => handleVideoClick('video2')}>Watch Tutorial</Button>
                </Box>
              </Box>
            </GridItem>
            <GridItem>
              <Box bg="white" shadow="md" borderRadius="md" overflow="hidden">
                <Image src="/path/to/tutorial-image.jpg" alt="Tutorial" />
                <Box p="4">
                  <Text fontSize="lg" fontWeight="bold">Using Fincraft for financial forecasting</Text>
                  <Text mb="4">Watch this video to learn how to use Fincraft for financial forecasting</Text>
                  <Button variant="link" colorScheme="blue" onClick={() => handleVideoClick('video3')}>Watch Tutorial</Button>
                </Box>
              </Box>
            </GridItem>
          </Grid>

          <Text fontSize="xl" fontWeight="bold" mt="8" mb="4">Popular articles</Text>
          <VStack align="stretch" spacing="4">
            {['How Fincraft can help your startup', 'Using Fincraft for financial reporting', 'Integrating Fincraft with QuickBooks', 'Customizing your Fincraft dashboard', 'Optimizing financial operations with Fincraft'].map((title, index) => (
              <Flex key={index} alignItems="center" bg="white" p="4" borderRadius="md" shadow="md">
                <Box flex="1">
                  <Text fontWeight="bold">{title}</Text>
                  <Text fontSize="sm" color="gray.500">2 min</Text>
                </Box>
                <Button variant="link" colorScheme="blue">Read Article</Button>
              </Flex>
            ))}
          </VStack>

          {selectedVideo && (
            <Box position="fixed" top="0" left="0" width="100vw" height="100vh" bg="rgba(0, 0, 0, 0.5)" display="flex" justifyContent="center" alignItems="center">
              <Box bg="white" p="8" borderRadius="md" shadow="md">
                <iframe width="560" height="315" src={selectedVideo} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                <Button mt="4" onClick={() => setSelectedVideo(null)}>Close</Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default KnowledgeBase;
