import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ChevronDownIcon, ChevronRightIcon, AddIcon, DeleteIcon, EmailIcon, ChatIcon } from '@chakra-ui/icons';
import Menu from './Menu';
import Header from './Header';
import {
  Button,
  Box,
  Flex,
  Text,
  Select,
  Input,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
} from '@chakra-ui/react';
import Cookies from 'js-cookie';

interface User {
  name: string;
  email: string;
  last_login: string;
  roles: { name: string }[];
}

interface Company {
  company_name: string;
  company_id: string;
  users: User[];
}

interface Update {
  task: string;
  date: string;
  status: string;
  priority: string;
  assignedTo: string;
}

const ClientPortal = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [newClientName, setNewClientName] = useState('');
  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState('Admin');
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
  const [showAddClientPopup, setShowAddClientPopup] = useState(false);
  const [showAddUserPopup, setShowAddUserPopup] = useState(false);
  const [expandedCompanies, setExpandedCompanies] = useState<{ [key: string]: boolean }>({});
  const [message, setMessage] = useState<string | null>(null);
  const [cfoName, setCfoName] = useState<string>('');
  const [cfoEmail, setCfoEmail] = useState<string>('');
  const [cfoId, setCfoId] = useState<string>('');
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [confirmDeleteCompanyId, setConfirmDeleteCompanyId] = useState<string | null>(null);
  const cancelRef = useRef(null);
  const toast = useToast();
  const navigate = useNavigate();

  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const companies1 = [
    { company_name: 'BlueWave Technologies', company_id: '1' },
    { company_name: 'Evergreen Financial', company_id: '2' },
    { company_name: 'NovaTech Solutions', company_id: '3' },
    { company_name: 'Global Ventures', company_id: '4' },
    { company_name: 'Crescent', company_id: '5' },
  ];
  const [updates, setUpdates] = useState<Update[]>([
    { task: 'Finalize Q3 Financials', date: 'Sep 25, 2024', status: 'In Progress', priority: 'High', assignedTo: 'John Smith, CFO' },
    { task: 'Client Onboarding - Global Ventures', date: 'Sep 26, 2024', status: 'Not Started', priority: 'Medium', assignedTo: 'Jane Smith, Bookkeeper' },
    { task: 'Internal Audit Preparation', date: 'Sep 27, 2024', status: 'In Progress', priority: 'High', assignedTo: 'Alice Brown, Controller' },
    { task: 'Tax Strategy Meeting', date: 'Sep 28, 2024', status: 'Completed', priority: 'High', assignedTo: 'Chris Ortiz, Analyst' },
    { task: 'Weekly Cash Flow Analysis', date: 'Sep 29, 2024', status: 'Not Started', priority: 'Low', assignedTo: 'Jane Smith, Bookkeeper' },
    { task: 'Monthly Reconciliation - BlueWave Technologies', date: 'Sep 29, 2024', status: 'In Progress', priority: 'Medium', assignedTo: 'Emily White, Controller' },
    { task: 'Payroll Processing', date: 'Sep 30, 2024', status: 'Not Started', priority: 'High', assignedTo: 'David Carter, Analyst' },
    { task: 'Budget Review for Client - Crescent', date: 'Oct 1, 2024', status: 'Not Started', priority: 'Medium', assignedTo: 'Lisa Black, CFO' },
    { task: 'Prepare Financial Projections', date: 'Oct 2, 2024', status: 'In Progress', priority: 'High', assignedTo: 'John Smith, CFO' },
    { task: 'Client Meeting - Evergreen Financial', date: 'Oct 3, 2024', status: 'Not Started', priority: 'Low', assignedTo: 'Alice Brown, Controller' },
  ]);

  const [approvals, setApprovals] = useState([
    { approvalItem: 'Expense Report - ABC Corp', status: 'Pending' },
    { approvalItem: 'Q3 Financial Review - DEF Inc.', status: 'Pending' },
  ]);

  useEffect(() => {
    checkAuthorization();
  }, []);

  const checkAuthorization = async () => {
    const token = Cookies.get('access_token');
    console.log('Token retrieved:', token);
    if (!token) {
      console.error('No token found');
      navigate('/login');
      return;
    }

    try {
      const response = await axios.get(`${apiDomain}/auth/current-user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const user = response.data;

      if (!user.roles || !user.roles.includes('CFO-Service')) {
        navigate('/client-portal');
        return;
      }

      setCfoName(user.name || user.email);
      setCfoEmail(user.email);
      setCfoId(user.sub);
      fetchCompanies();
    } catch (error) {
      console.error('Error checking authorization:', error);
      navigate('/login');
    }
  };

  const fetchCompanies = async () => {
    const token = Cookies.get('access_token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const response = await axios.get(`${apiDomain}/auth/companies`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('Response data:', response.data);

      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setMessage('Error fetching companies.');
    }
  };

  const handleSaveClient = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = Cookies.get('access_token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      await axios.post(`${apiDomain}/auth/otp`, {
        company_name: newClientName,
        user_name: newUserName,
        user_email: newUserEmail,
        role: newUserRole,
        isNewClient: true,
        cfo_id: cfoId,
        cfo_email: cfoEmail,
        cfo_role: 'CFO-Service',
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchCompanies();
      setMessage('An email has been sent to the user to set up their account.');
      toast({
        title: 'Client added successfully.',
        description: 'An email has been sent to the user to set up their account.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setShowAddClientPopup(false);
      resetForm();
    } catch (error) {
      console.error('Error generating login URL:', error);
      setMessage('Error generating login URL.');
    }
  };

  const handleSaveUser = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = Cookies.get('access_token');
    if (!token || !selectedCompanyId) {
      console.error('No token or company ID found');
      return;
    }

    try {
      await axios.post(`${apiDomain}/auth/otp`, {
        company_id: selectedCompanyId,
        company_name: newClientName,
        user_name: newUserName,
        user_email: newUserEmail,
        role: newUserRole,
        isNewClient: false,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchCompanies();
      setMessage('An email has been sent to the user to set up their account.');
      toast({
        title: 'User added successfully.',
        description: 'An email has been sent to the user to set up their account.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setShowAddUserPopup(false);
      resetForm();
    } catch (error) {
      console.error('Error generating login URL:', error);
      setMessage('Error generating login URL.');
    }
  };

  const resetForm = () => {
    setNewClientName('');
    setNewUserName('');
    setNewUserEmail('');
    setNewUserRole('Admin');
  };

  const handleAddUserClick = (companyId: string) => {
    setSelectedCompanyId(companyId);
    setShowAddUserPopup(true);
  };
  const handleDeleteUser = (companyId: string) => {
    // Handle deleting a user
    console.log(`Deleting user from company ID: ${companyId}`);
  };

  const toggleCompanyExpansion = (companyId: string) => {
    setExpandedCompanies((prevState) => ({
      ...prevState,
      [companyId]: !prevState[companyId],
    }));
  };

  const handleDeleteWorkspace = async () => {
    if (!confirmDeleteCompanyId) return;
    const token = Cookies.get('access_token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      await axios.post(`${apiDomain}auth/delete-workspace`, {
        company_id: confirmDeleteCompanyId,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchCompanies();
      toast({
        title: 'Workspace deleted successfully.',
        description: 'The workspace has been deleted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteAlertOpen(false);
    } catch (error) {
      console.error('Error deleting workspace:', error);
      setMessage('Error deleting workspace.');
    }
  };

  const openDeleteAlert = (companyId: string) => {
    setConfirmDeleteCompanyId(companyId);
    setIsDeleteAlertOpen(true);
  };

  const handleAccessWorkspace = async (companyId: string) => {
    const token = Cookies.get('access_token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const response = await axios.post(`${apiDomain}/auth/generate-client-access-token`, {
        clientId: companyId,
        cfoId,
        cfoRole: 'CFO-Service',
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { token: clientToken } = response.data;
      Cookies.set('client_access_token', clientToken);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error generating access token:', error);
      setMessage('Error generating access token.');
    }
  };

  const handleStatusChange = (index: number) => {
    setUpdates((prevUpdates) => {
      return prevUpdates.map((update, i) => {
        if (i === index) {
          const nextStatus = update.status === 'In Progress'
            ? 'Done'
            : update.status === 'Done'
              ? 'Not Started'
              : 'In Progress';
          return { ...update, status: nextStatus };
        }
        return update;
      });
    });
  };

  const handleApprovalAction = (index: number, action: string) => {
    setApprovals((prevApprovals) =>
      prevApprovals.map((approval, i) =>
        i === index
          ? { ...approval, status: action === 'Approve' ? 'Approved' : 'Reviewing' }
          : approval
      )
    );
  };

  return (
    <Flex minHeight="100vh" direction="column">
      <Header />
      <Flex flex="1">
        <Menu />
        <Box flex="1" p="8">
          <Box mb="8">
            <Text as="h1" fontSize="3xl" fontWeight="bold">Good morning, {cfoName}</Text>
            <Text fontSize="lg">Here are your updates for today:</Text>
          </Box>
          <Box bg="white" shadow="md" borderRadius="md" p="4" mb="8" display="flex" flexDirection="row" justifyContent="space-between">
            {/* Left Side: Updates Section */}
            <Box flex="2" mr="4">
              <Text fontSize="xl" fontWeight="bold" mb="4">Updates</Text>
              <Box as="table" width="100%">
                <Box as="thead">
                  <Box as="tr">
                    <Box as="th" textAlign="left" width="40%">Task</Box>
                    <Box as="th" textAlign="left" width="15%">Date</Box>
                    <Box as="th" textAlign="left" width="15%">Status</Box>
                    <Box as="th" textAlign="left" width="15%">Priority</Box>
                    <Box as="th" textAlign="left" width="15%">Assigned To</Box>
                  </Box>
                </Box>
                <Box as="tbody">
                  {updates.map((update, index) => (
                    <Box as="tr" key={index}>
                      <Box as="td" width="40%">{update.task}</Box>
                      <Box as="td" width="15%">{update.date}</Box>
                      <Box as="td" width="15%">
                        <Button
                          size="sm"
                          colorScheme={update.status === 'In Progress' ? 'blue' : update.status === 'Done' ? 'green' : 'purple'}
                          onClick={() => handleStatusChange(index)}
                        >
                          {update.status}
                        </Button>
                      </Box>
                      <Box as="td" width="15%">{update.priority}</Box>
                      <Box as="td" width="15%">{update.assignedTo}</Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            {/* Right Side: Alert/Pending Approvals Section */}
            <Box flex="1" ml="4">
              <Text fontSize="xl" fontWeight="bold" mb="4">Alert / Pending Approvals</Text>
              <Box bg="gray.50" borderRadius="md" p="4">
                {approvals.map((approval, index) => (
                  <Flex key={index} mb="4" alignItems="center">
                    <Box flex="1">
                      <Text fontSize="md" fontWeight="medium">{approval.approvalItem}</Text>
                    </Box>
                    <Button
                      size="xs"
                      colorScheme="yellow"
                      onClick={() => handleApprovalAction(index, 'Review')}
                      mr="2"
                    >
                      Review
                    </Button>
                    <Button
                      size="xs"
                      colorScheme="green"
                      onClick={() => handleApprovalAction(index, 'Approve')}
                      mr="2"
                    >
                      Approve
                    </Button>
                    <IconButton
                      size="xs"
                      aria-label="Send to Email"
                      icon={<EmailIcon />}
                      mr="2"
                    />
                    <IconButton
                      size="xs"
                      aria-label="Send to Chat"
                      icon={<ChatIcon />}
                    />
                  </Flex>
                ))}
              </Box>
            </Box>
          </Box>
          <Box>
            <Text fontSize="xl" fontWeight="bold" mb="4">Clients</Text>
            <Button onClick={() => setShowAddClientPopup(true)} className="btn" mb="4" size="sm">Add New Client</Button>
            {message && (
              <Box bg="green.100" border="1px" borderColor="green.400" textColor="green.700" px="4" py="3" borderRadius="md" mb="4">
                <Text>{message}</Text>
              </Box>
            )}
            <Box bg="white" shadow="md" borderRadius="md" p="4">
              {companies1.length > 0 ? (
                companies1.map((company) => (
                  <Box>
                    <Text fontSize="xl" fontWeight="bold" mb="4">Clients</Text>
                    {companies1.map((company) => (
                      <Box key={company.company_id} mb="4" p="4" border="1px solid" borderColor="gray.200" borderRadius="md">
                        <Flex justifyContent="space-between" alignItems="center">
                          <Flex alignItems="center">
                            <Button onClick={() => toggleCompanyExpansion(company.company_id)} colorScheme="purple" size="sm" mr="2">
                              {expandedCompanies[company.company_id] ? <ChevronDownIcon /> : <ChevronRightIcon />}
                            </Button>
                            <Text fontSize="xl" fontWeight="bold">{company.company_name}</Text>
                          </Flex>
                          <Flex>
                            <Button onClick={() => handleAccessWorkspace(company.company_id)} colorScheme="blue" size="sm" mr="2">Workspace</Button>
                            <Button onClick={() => handleAddUserClick(company.company_id)} colorScheme="green" size="sm" mr="2">
                              <AddIcon />
                            </Button>
                            <Button onClick={() => handleDeleteUser(company.company_id)} colorScheme="red" size="sm">
                              <DeleteIcon />
                            </Button>
                          </Flex>
                        </Flex>
                      </Box>
                    ))}
                  </Box>
                ))
              ) : (
                <Text>No companies found.</Text>
              )}
            </Box>
          </Box>
          {showAddClientPopup && (
            <Box position="fixed" inset="0" display="flex" alignItems="center" justifyContent="center" bg="blackAlpha.50">
              <Box bg="white" p="6" borderRadius="md" shadow="md" w="full" maxW="md">
                <Text fontSize="xl" fontWeight="bold" mb="4">Add New Client</Text>
                <form onSubmit={handleSaveClient}>
                  <Box mb="4">
                    <Text>Company Name:</Text>
                    <Input
                      type="text"
                      value={newClientName}
                      onChange={(e) => setNewClientName(e.target.value)}
                      required
                    />
                  </Box>
                  <Box mb="4">
                    <Text>User Name:</Text>
                    <Input
                      type="text"
                      value={newUserName}
                      onChange={(e) => setNewUserName(e.target.value)}
                      required
                    />
                  </Box>
                  <Box mb="4">
                    <Text>User Email:</Text>
                    <Input
                      type="email"
                      value={newUserEmail}
                      onChange={(e) => setNewUserEmail(e.target.value)}
                      required
                    />
                  </Box>
                  <Box mb="4">
                    <Text>User Role:</Text>
                    <Select
                      value={newUserRole}
                      onChange={(e) => setNewUserRole(e.target.value)}
                      required
                    >
                      <option value="Admin">Admin</option>
                      <option value="Analyst">Analyst</option>
                      <option value="CFO-Service">CFO-Service</option>
                    </Select>
                  </Box>
                  <Flex justifyContent="flex-end">
                    <Button onClick={() => setShowAddClientPopup(false)} variant="cancel" mr={2}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="save">
                      Save
                    </Button>
                  </Flex>
                </form>
              </Box>
            </Box>
          )}
          {showAddUserPopup && (
            <Box position="fixed" inset="0" display="flex" alignItems="center" justifyContent="center" bg="blackAlpha.50">
              <Box bg="white" p="6" borderRadius="md" shadow="md" w="full" maxW="md">
                <Text fontSize="xl" fontWeight="bold" mb="4">Add New User</Text>
                <form onSubmit={handleSaveUser}>
                  <Box mb="4">
                    <Text>User Name:</Text>
                    <Input
                      type="text"
                      value={newUserName}
                      onChange={(e) => setNewUserName(e.target.value)}
                      required
                    />
                  </Box>
                  <Box mb="4">
                    <Text>User Email:</Text>
                    <Input
                      type="email"
                      value={newUserEmail}
                      onChange={(e) => setNewUserEmail(e.target.value)}
                      required
                    />
                  </Box>
                  <Box mb="4">
                    <Text>User Role:</Text>
                    <Select
                      value={newUserRole}
                      onChange={(e) => setNewUserRole(e.target.value)}
                      required
                    >
                      <option value="Admin">Admin</option>
                      <option value="Analyst">Analyst</option>
                      <option value="CFO-Service">CFO-Service</option>
                    </Select>
                  </Box>
                  <Flex justifyContent="flex-end">
                    <Button onClick={() => setShowAddUserPopup(false)} variant="cancel" mr={2}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="save">
                      Save
                    </Button>
                  </Flex>
                </form>
              </Box>
            </Box>
          )}
          <AlertDialog
            isOpen={isDeleteAlertOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => setIsDeleteAlertOpen(false)}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Workspace
                </AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure you want to delete this workspace? This action cannot be undone.
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={() => setIsDeleteAlertOpen(false)} variant="cancel">
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={handleDeleteWorkspace} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Box>
      </Flex>
    </Flex>
  );
}
export default ClientPortal;
