import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomLogin from './CustomLogin';

const HomePage = () => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('access_token');

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [isAuthenticated, navigate]);

  return (
    <div>
      <h1>Home Page</h1>
      {isAuthenticated ? (
        <div>
          <p>Welcome! You are logged in.</p>
          <button
            onClick={() => {
              localStorage.removeItem('access_token');
              localStorage.removeItem('id_token');
              navigate('/login'); // Redirect to login on logout
            }}
          >
            Log Out
          </button>
        </div>
      ) : (
        <CustomLogin />
      )}
    </div>
  );
};

export default HomePage;
