import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const ComingSoon = () => {
  const navigate = useNavigate();

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center" bg="gray.100">
      <Box textAlign="center" p={8} bg="white" shadow="md" borderRadius="md">
        <Text fontSize="2xl" fontWeight="bold" mb={4}>Coming Soon</Text>
        <Text mb={4}>This feature is under development and will be available soon.</Text>
        <Button colorScheme="blue" onClick={() => navigate('/')}>Go to Home</Button>
      </Box>
    </Flex>
  );
};

export default ComingSoon;
