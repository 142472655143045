import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaHome, FaUsers, FaUserCog, FaPlug } from 'react-icons/fa';
import { AiOutlineSetting } from 'react-icons/ai';
import { MdOutlineAssessment } from 'react-icons/md';
import { BiBot } from 'react-icons/bi'; // AI Assistant icon

const Menu = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [role, setRole] = useState(''); // Assuming you get this from your auth provider
  const toggleMenu = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    // Fetch user role from your auth provider or state management
    const userRole = 'Other-Roles'; // Example role; replace with actual role fetching logic
    setRole(userRole);
  }, []);

  const cfoServiceMenuItems = [
    { to: '/client-portal', icon: FaHome, label: 'Home' },
    { to: '/chat', icon: BiBot, label: 'AI Assistant' },
  ];

  const otherRolesMenuItems = [
    { to: '/dashboard', icon: FaHome, label: 'Home' },
    { to: '/chat', icon: BiBot, label: 'AI Assistant' },
    { to: '/teammates', icon: MdOutlineAssessment, label: 'Teammates' },
    { to: '/connectors', icon: FaPlug, label: 'Connectors' },
  ];

  const commonBottomMenuItems = [
    { to: '/settings', icon: AiOutlineSetting, label: 'Settings' },
    { to: '/users', icon: FaUserCog, label: 'User Management' },
  ];

  const getMenuItems = () => {
    if (role === 'CFO-Service') {
      return [...cfoServiceMenuItems, ...commonBottomMenuItems];
    }
    return [...otherRolesMenuItems, ...commonBottomMenuItems];
  };

  const menuItems = getMenuItems();

  return (
    <div className={`bg-fuchsia-700 text-white shadow-md min-h-screen ${isExpanded ? 'w-64' : 'w-16'}`}>
      <div className="p-2 flex justify-center items-center h-16">
        <button
          onClick={toggleMenu}
          className="text-white focus:outline-none"
        >
          {isExpanded ? <FaTimes size="24px" /> : <FaBars size="24px" />}
        </button>
      </div>
      <div className="mt-2 space-y-2 flex flex-col items-start px-2">
        {menuItems.map((item) => (
          isExpanded ? (
            <Link to={item.to} key={item.label}>
              <div className="flex items-center ml-4 py-2 px-2 rounded-md hover:bg-fuchsia-600">
                <item.icon size="24px" />
                <span className="ml-4">{item.label}</span>
              </div>
            </Link>
          ) : (
            <Link to={item.to} key={item.label}>
              <div className="ml-2 py-2 px-2 rounded-md hover:bg-fuchsia-600 flex justify-center">
                <item.icon size="24px" />
              </div>
            </Link>
          )
        ))}
      </div>
    </div>
  );
};

export default Menu;
