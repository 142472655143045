import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, GridItem, Text, useColorModeValue } from '@chakra-ui/react';
import axios from 'axios';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Menu from './Menu';
import Header from './Header';
import Cookies from 'js-cookie';

interface MRRData {
  month: string;
  totalRevenue: number;
}

const Dashboard: React.FC = () => {
  const [mrr, setMrr] = useState<number | null>(null);
  const [mrrChange, setMrrChange] = useState<number | null>(null);
  const [cogs, setCogs] = useState<number | null>(null);
  const [cogsChange, setCogsChange] = useState<number | null>(null);
  const [grossProfit, setGrossProfit] = useState<number | null>(null);
  const [profitMarginChange, setProfitMarginChange] = useState<number | null>(null);
  const [burnRate, setBurnRate] = useState<number | null>(null);
  const [burnRateChange, setBurnRateChange] = useState<number | null>(null);
  const [mrrData, setMrrData] = useState<MRRData[]>([]);
  const [dailyActiveUsers, setDailyActiveUsers] = useState<number | null>(null);
  const [monthlyActiveUsers, setMonthlyActiveUsers] = useState<number | null>(null);
  const [historicalUsers, setHistoricalUsers] = useState<any[]>([]);;
  //const [churnRate, setChurnRate] = useState<number | null>(null);
  const [cac, setCac] = useState<number | null>(null);
  const [cltv, setCltv] = useState<number | null>(null);
  const [lastModifiedDate, setLastModifiedDate] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<number>(2024);
  const churnRate = 5.7;
  const [churnHistData, setChurnData] = useState<any | null>(null);

  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  interface MRRData {
    year: number;
    month: string;
    totalRevenue: number;
  }

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('access_token');
      if (!token) {
        console.error('No token found');
        return;
      }
      const userData = [
        { month: "05", value: 1540 },
        { month: "06", value: 955 },
        { month: "07", value: 963 },
        { month: "08", value: 1054 },
        { month: "09", value: 1559 }
      ];
      const formattedMRRData = [
        { year: 2024, month: "2024-01", totalRevenue: 801.75 },
        { year: 2024, month: "2024-02", totalRevenue: 1387.00 },
        { year: 2024, month: "2024-03", totalRevenue: 2062.40 },
        { year: 2024, month: "2024-04", totalRevenue: 4406.42 },
        { year: 2024, month: "2024-05", totalRevenue: 4657.82 },
        { year: 2024, month: "2024-06", totalRevenue: 3700.00 },
        { year: 2024, month: "2024-07", totalRevenue: 3875.54 },
        { year: 2024, month: "2024-08", totalRevenue: 4062.75 }
      ];
      const churnRateData = [
        { month: '2024-01', churnRate: 5.2 },
        { month: '2024-02', churnRate: 5.4 },
        { month: '2024-03', churnRate: 5.1 },
        { month: '2024-04', churnRate: 5.1 },
        { month: '2024-05', churnRate: 5.2 },
        { month: '2024-06', churnRate: 5.2 },
        { month: '2024-07', churnRate: 5.4 },
        { month: '2024-08', churnRate: 5.7 },
      ];
      try {
        const [
          mrrRes,
          cogsRes,
          profitMarginRes,
          burnRateRes,
          mrrDataRes,
          dailyUsersRes,
          historicalUsersRes,
          churnRateRes,
          cacRes,
          cltvRes,
        ] = await Promise.all([
          axios.get(`${apiDomain}/dashboard/mrr`, {
            headers: { Authorization: `Bearer ${token}` },
          }).catch(() => ({ data: { totalRevenue: null, revenueChange: null } })),
          axios.get(`${apiDomain}/dashboard/cogs`, {
            headers: { Authorization: `Bearer ${token}` },
          }).catch(() => ({ data: { totalCogs: null, cogsChange: null } })),
          axios.get(`${apiDomain}/dashboard/gross-profit`, {
            headers: { Authorization: `Bearer ${token}` },
          }).catch(() => ({ data: { grossProfit: null, grossProfitChange: null } })),
          axios.get(`${apiDomain}/dashboard/net-burn-rate`, {
            headers: { Authorization: `Bearer ${token}` },
          }).catch(() => ({ data: { netBurnRate: null, netBurnRateChange: null } })),
          axios.get(`${apiDomain}/dashboard/mrr-data`, {
            headers: { Authorization: `Bearer ${token}` },
          })
            .then(response => response.data)
            .catch(() => []),
          axios.get(`${apiDomain}/dashboard/fetch-active-users`, {
            headers: { Authorization: `Bearer ${token}` },
          }).catch(() => ({ data: { dailyActiveUsers: null, monthlyActiveUsers: null } })),
          axios.get(`${apiDomain}/dashboard/fetch-historical-users`, {
            headers: { Authorization: `Bearer ${token}` },
          }).catch(() => ({ data: { historicalData: [] } })),
          axios.get(`${apiDomain}/dashboard/churn-rate`, {
            headers: { Authorization: `Bearer ${token}` },
          }).catch(() => ({ data: { churnRate: null } })),
          axios.get(`${apiDomain}/dashboard/cac`, {
            headers: { Authorization: `Bearer ${token}` },
          }).catch(() => ({ data: { cac: null } })),
          axios.get(`${apiDomain}/dashboard/cltv`, {
            headers: { Authorization: `Bearer ${token}` },
          }).catch(() => ({ data: { cltv: null } })),
        ]);

        setMrr(mrrRes.data.totalRevenue);
        setMrrChange(mrrRes.data.revenueChange);
        setCogs(cogsRes.data.totalCogs);
        setCogsChange(cogsRes.data.cogsChange);
        setGrossProfit(profitMarginRes.data.grossProfit);
        setProfitMarginChange(profitMarginRes.data.profitMarginChange);
        setBurnRate(burnRateRes.data.netBurnRate);
        setBurnRateChange(burnRateRes.data.netBurnRateChange);
        setDailyActiveUsers(dailyUsersRes.data.dailyActiveUsers);
        setMonthlyActiveUsers(dailyUsersRes.data.monthlyActiveUsers);
        //setHistoricalUsers(historicalUsersRes.data)
        setHistoricalUsers(userData);
        setChurnData(churnRateData);
        setCac(cacRes.data.cac);
        setCltv(cltvRes.data.cltv);

        const formattedData = mrrDataRes.map((item: MRRData) => {
          const monthFormatted = `${item.year}-${String(item.month).padStart(2, '0')}`;
          const totalRevenueParsed = parseFloat(item.totalRevenue as unknown as string);
          console.log('Formatted Data:', { monthFormatted, totalRevenueParsed });
          return {
            ...item,
            month: monthFormatted,
            totalRevenue: totalRevenueParsed,   // Ensure this is a valid number
          };
        });

        setMrrData(formattedMRRData);
      } catch (err: any) {
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
    setLastModifiedDate(new Date().toLocaleString());
  }, [apiDomain, selectedYear]);

  // Clean the data to filter out invalid values
  const cleanedData = mrrData.filter(
    (d) => d.totalRevenue !== null && !isNaN(d.totalRevenue) && isFinite(d.totalRevenue)
  );

  // // Function to calculate dynamic ticks
  const getYAxisTicks = (dataMax: number) => {
    const tickCount = 5; // Number of ticks you want
    const interval = Math.ceil(dataMax / tickCount);
    return Array.from({ length: tickCount + 1 }, (_, i) => interval * i);
  };

  // // Calculate max value from the cleaned data
  const maxRevenue = Math.max(...cleanedData.map((d) => d.totalRevenue), 0);
  const yAxisTicks = getYAxisTicks(maxRevenue);

  const cardBg = useColorModeValue('white', 'gray.800');
  const cardColor = useColorModeValue('gray.700', 'white');


  return (
    <Box display="flex" minHeight="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <Menu />
      <Box flex="1">
        <Header />
        <Box p="8">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb="8">
            <Text fontSize="2xl" fontWeight="bold">Dashboard</Text>
            <Text color="gray.500">Last updated: {lastModifiedDate}</Text>
            {/* <button className="btn">Manage</button> */}
          </Box>

          <Grid templateColumns="repeat(4, 1fr)" gap="4">
            {/* MRR */}
            <GridItem>
              <Box bg={cardBg} p="4" shadow="md" borderRadius="md">
                <Text color="gray.600">MRR</Text>
                {mrr !== null ? (
                  <>
                    <Text fontSize="2xl" fontWeight="bold">${mrr}</Text>
                    <Text color={mrrChange !== null && mrrChange > 0 ? 'green.500' : 'red.500'}>
                      {mrrChange !== null && mrrChange > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />} {mrrChange}%
                    </Text>
                  </>
                ) : (
                  <Text>No data available</Text>
                )}
              </Box>
            </GridItem>

            {/* COGS */}
            <GridItem>
              <Box bg={cardBg} p="4" shadow="md" borderRadius="md">
                <Text color="gray.600">COGS</Text>
                {cogs !== null ? (
                  <>
                    <Text fontSize="2xl" fontWeight="bold">${cogs}</Text>
                    <Text color={cogsChange !== null && cogsChange > 0 ? 'red.500' : 'green.500'}>
                      {cogsChange !== null && cogsChange > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />} {cogsChange}%
                    </Text>
                  </>
                ) : (
                  <Text>No data available</Text>
                )}
              </Box>
            </GridItem>

            {/* Gross Profit */}
            <GridItem>
              <Box bg={cardBg} p="4" shadow="md" borderRadius="md">
                <Text color="gray.600">Gross Profit</Text>
                {grossProfit !== null ? (
                  <>
                    <Text fontSize="2xl" fontWeight="bold">${grossProfit}</Text>
                    <Text color={profitMarginChange !== null && profitMarginChange > 0 ? 'green.500' : 'red.500'}>
                      {profitMarginChange !== null && profitMarginChange > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />} {profitMarginChange}%
                    </Text>
                  </>
                ) : (
                  <Text>No data available</Text>
                )}
              </Box>
            </GridItem>

            {/* Net Burn Rate */}
            <GridItem>
              <Box bg={cardBg} p="4" shadow="md" borderRadius="md">
                <Text color="gray.600">Net Burn Rate</Text>
                {burnRate !== null ? (
                  <>
                    <Text fontSize="2xl" fontWeight="bold">${burnRate}</Text>
                    <Text color={burnRateChange !== null && burnRateChange > 0 ? 'red.500' : 'green.500'}>
                      {burnRateChange !== null && burnRateChange > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />} {burnRateChange}%
                    </Text>
                  </>
                ) : (
                  <Text>No data available</Text>
                )}
              </Box>
            </GridItem>
          </Grid>

          <Grid templateColumns="4fr 1fr" gap="4" mt="4">
            {/* MRR Over Time */}
            <GridItem>
              <Box bg={cardBg} p="4" shadow="md" borderRadius="md">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Text fontSize="3xl" fontWeight="bold" color="gray.600">MRR Over Time</Text>
                  <Box>
                    <select value={selectedYear} onChange={(e) => setSelectedYear(Number(e.target.value))}>
                      <option value={2024}>2024</option>
                      <option value={2023}>2023</option>
                      <option value={2022}>2022</option>
                    </select>
                  </Box>
                </Box>
                <Box mt="4" height="400px" bg="gray.100">
                  <ResponsiveContainer key={selectedYear} width="100%" height="100%">
                    <LineChart data={mrrData}>
                      <XAxis dataKey="month" />
                      <YAxis
                        domain={[0, 5000]}
                        ticks={[0, 1000, 2000, 3000, 4000, 5000]}
                        allowDataOverflow={true}
                        padding={{ top: 20 }}
                      />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="totalRevenue" stroke="#8884d8" />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </GridItem>

            {/* Active Users */}
            <GridItem>
              <Box bg="gray.800" p="4" shadow="md" borderRadius="md">
                <Text fontSize="2xl" color="magenta">Active Users</Text>
                <Text fontSize="2xl" fontWeight="bold">
                  {monthlyActiveUsers ? Number(monthlyActiveUsers).toLocaleString() : 'No data available'}
                </Text>
                <Box display="flex" justifyContent="space-between" color="gray.500">
                  <Text fontSize="2xl" fontWeight="bold" color="gray.400">
                    {monthlyActiveUsers ? `${Number(monthlyActiveUsers).toLocaleString()} Monthly` : 'No data available'}
                  </Text>
                  <Text fontSize="2xl" fontWeight="bold" color="gray.400">
                    {dailyActiveUsers ? `${Number(dailyActiveUsers).toLocaleString()} Daily` : 'No data available'}
                  </Text>
                </Box>
                <Box mt="4">
                  <ResponsiveContainer width="100%" height={320}>
                    <BarChart data={historicalUsers} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
                      <XAxis dataKey="month" />
                      <Tooltip />
                      <Bar dataKey="value" fill="#9146ff" barSize={20} />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </GridItem>
          </Grid>

          <Grid templateColumns="repeat(2, 1fr)" gap="4" mt="4">
            {/* CLTV */}
            <GridItem>
              <Box bg="gray.800" p="6" shadow="md" borderRadius="md" textAlign="center">
                <Text color="gray.400" fontSize="md">Customer Lifetime Value</Text>
                <Text fontSize="4xl" fontWeight="bold" color="magenta">
                  18 months
                </Text>
                <Text fontSize="3xl" fontWeight="bold" color="gray.400">
                  $24,765
                </Text>
              </Box>
            </GridItem>
            {/* Churn Rate */}
            <GridItem>
              <Box bg={cardBg} p="4" shadow="md" borderRadius="md">
                <Text color="gray.800">Churn Rate</Text>
                <Text fontSize="sm" color="gray.400">
                  {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}
                </Text>
                <Box display="flex" alignItems="center" mt="4">
                  <Box flex="1">
                    <Text fontSize="3xl" fontWeight="bold" color="gray.400">
                      {churnRate.toFixed(1)}%
                    </Text>
                  </Box>
                  <Box flex="2">
                    <ResponsiveContainer width="100%" height={80}>
                      <LineChart data={churnHistData}>
                        <XAxis dataKey="month" />
                        <YAxis domain={[5.0, 6.0]} hide />
                        <Tooltip formatter={(value) => `${value}%`} />
                        <Line type="monotone" dataKey="churnRate" stroke="#8884d8" dot={false} />
                        <Line
                          type="linear"
                          dataKey="churnRate"
                          strokeDasharray="3 3"
                          dot={false}
                          stroke="#82ca9d"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>
                </Box>
              </Box>
            </GridItem>

          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
