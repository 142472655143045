import { extendTheme, ThemeConfig } from '@chakra-ui/react';

// Define the color palette based on the mcgpalette0 configuration
const colors = {
  mcgpalette0: {
    50: '#efe2ed',    // Light pinkish-purple
    100: '#d7b8d2',   // Light purple
    200: '#bd88b5',   // Medium purple
    300: '#a35897',   // Strong purple
    400: '#8f3580',   // Darker purple
    500: '#7b116a',   // Primary purple
    600: '#730f62',   // Slightly darker
    700: '#680c57',   // Dark purple
    800: '#5e0a4d',   // Darker purple
    900: '#4b053c',   // Darkest purple
    A100: '#ff80de',  // Accent light pink
    A200: '#ff4dd1',  // Accent bright pink
    A400: '#ff1ac3',  // Accent bold pink
    A700: '#ff00bd',  // Accent neon pink
  },
};

// Set up theme configuration
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

// Define contrast colors
const contrastDefaultColor = 'light';
const contrastDarkColors = ['50', '100', '200', 'A100', 'A200'];
const contrastLightColors = ['300', '400', '500', '600', '700', '800', '900', 'A400', 'A700'];

const theme = extendTheme({
  config,
  colors,
  // Optionally, you could define other components or styles here if needed
});

export default theme;
