import React, { useState, useEffect } from 'react';
import { Box, Button, Input, VStack, HStack, Text, useBreakpointValue, Flex, Divider, Avatar, IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure, Spinner, Wrap, WrapItem } from '@chakra-ui/react';
import { AddIcon, EditIcon, AttachmentIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import MenuBar from './Menu';
import FincraftBot from './assets/fincraft-img-256.png';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

interface ChatMessage {
  role: 'user' | 'assistant' | 'system';
  content: string;
  timestamp: string;
  userId: string;
}

interface ChatSession {
  id: string;
  name: string;
  messages: ChatMessage[];
  createdAt: string;
  userId: string;
}

const ChatInterface: React.FC = () => {
  const [sessions, setSessions] = useState<ChatSession[]>([]);
  const [activeSessionId, setActiveSessionId] = useState<string | null>(null);
  const [input, setInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newName, setNewName] = useState<string>('');
  const [renameSessionId, setRenameSessionId] = useState<string | null>(null);
  const [showOptions, setShowOptions] = useState<boolean>(true); // Added showOptions state
  const navigate = useNavigate();
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    const token = Cookies.get('access_token');
    if (!token) {
      console.log('No token found, redirecting to login');
      navigate('/login');
      return;
    }

    const fetchUserId = async () => {
      try {
        const response = await axios.get(`${apiDomain}/auth/current-user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedUserId = response.data.user_id;
        setUserId(fetchedUserId);
        return fetchedUserId;
      } catch (error) {
        console.error('Error fetching user ID:', error);
        navigate('/login');
      }
    };

    const initializeChat = async () => {
      const currentUserId = Cookies.get('user_id') || await fetchUserId();
      if (!currentUserId) {
        navigate('/login');
        return;
      }

      const fetchHistory = async () => {
        try {
          console.log('Fetching chat history for user:', currentUserId);
          const response = await axios.get(`${apiDomain}/flask/chat/history`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              userId: currentUserId,
            },
          });
          console.log('Response received:', response);
          const fetchedSessions: ChatSession[] = response.data;
          console.log('Fetched sessions:', fetchedSessions);

          setSessions(fetchedSessions);
          if (fetchedSessions.length > 0) {
            setActiveSessionId(fetchedSessions[0].id);
            setShowOptions(false); // Hide options if there are existing sessions
            console.log('Set active session ID to:', fetchedSessions[0].id);
          } else {
            createNewSession();  // Create a new session if none exist
          }
        } catch (error) {
          console.error('Error fetching chat history:', error);
          if (axios.isAxiosError(error) && error.response?.status === 401) {
            navigate('/login');
          }
        } finally {
          setLoading(false);
        }
      };
      fetchHistory();
    };

    initializeChat();
  }, [navigate, apiDomain]);

  const createNewSession = (initialMessage?: string) => {
    const newSessionId = uuidv4();
    const newSession: ChatSession = {
      id: newSessionId,
      name: `Chat-${Math.floor(Math.random() * 10000)}`,
      messages: [],
      createdAt: new Date().toISOString(),
      userId: userId || 'unknown',
    };
    console.log('Creating new session:', newSession);
    setSessions((prevSessions) => [...prevSessions, newSession]);
    setActiveSessionId(newSession.id);
    if (initialMessage) {
      sendMessage(initialMessage, newSession.id);  // Pass new session ID
    }
    console.log('New session created with ID:', newSession.id);
    setShowOptions(false);
  };

  const sendMessage = async (query: string, sessionId?: string) => {
    const token = Cookies.get('access_token');
    const targetSessionId = sessionId || activeSessionId;
    if (!token || !targetSessionId) return;

    const activeSessionIndex = sessions.findIndex(session => session.id === targetSessionId);
    const activeSession = sessions[activeSessionIndex];

    if (!activeSession) {
      console.error('Active session not found');
      return;
    }

    const userMessage: ChatMessage = { role: 'user', content: query, timestamp: new Date().toISOString(), userId: userId || 'unknown' };
    const updatedMessages = [...activeSession.messages, userMessage];
    const updatedSession = { ...activeSession, messages: updatedMessages };

    setSessions((prevSessions) => [
      ...prevSessions.slice(0, activeSessionIndex),
      updatedSession,
      ...prevSessions.slice(activeSessionIndex + 1)
    ]);

    setInput('');

    try {
      console.log('Sending message:', query, 'for session:', targetSessionId);
      const response = await axios.post(`${apiDomain}/flask/chat`, { query, sessionId: targetSessionId, userId: userId || 'unknown' }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Response from backend:', response);

      // Handle the case where more information is needed
      if (response.data.reply) {
        const botMessage: ChatMessage = { role: 'assistant', content: response.data.reply, timestamp: new Date().toISOString(), userId: 'bot' };
        const updatedMessagesWithReply = [...updatedMessages, botMessage];
        const updatedSessionWithReply = { ...updatedSession, messages: updatedMessagesWithReply };

        setSessions((prevSessions) => [
          ...prevSessions.slice(0, activeSessionIndex),
          updatedSessionWithReply,
          ...prevSessions.slice(activeSessionIndex + 1)
        ]);
      } else if (response.data.summary) {
        // Handle the regular summary response
        const botMessage: ChatMessage = { role: 'assistant', content: response.data.summary, timestamp: new Date().toISOString(), userId: 'bot' };
        const updatedMessagesWithSummary = [...updatedMessages, botMessage];
        const updatedSessionWithSummary = { ...updatedSession, messages: updatedMessagesWithSummary };

        setSessions((prevSessions) => [
          ...prevSessions.slice(0, activeSessionIndex),
          updatedSessionWithSummary,
          ...prevSessions.slice(activeSessionIndex + 1)
        ]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = error instanceof Error && error.message.includes('table or data')
        ? 'Error processing request as this data does not exist in your database.'
        : 'Error processing your request';
      const botMessage: ChatMessage = { role: 'assistant', content: errorMessage, timestamp: new Date().toISOString(), userId: 'bot' };
      const updatedMessagesWithError = [...updatedMessages, botMessage];
      const updatedSessionWithError = { ...updatedSession, messages: updatedMessagesWithError };

      setSessions((prevSessions) => [
        ...prevSessions.slice(0, activeSessionIndex),
        updatedSessionWithError,
        ...prevSessions.slice(activeSessionIndex + 1)
      ]);
    }
  };

  const handleOptionClick = (option: string) => {
    if (!activeSessionId) {
      createNewSession(option);
    } else {
      sendMessage(option);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sendMessage(input);
    }
  };

  const handleRename = (sessionId: string) => {
    const sessionIndex = sessions.findIndex(session => session.id === sessionId);
    const updatedSessions = [...sessions];
    updatedSessions[sessionIndex].name = newName;

    setSessions(updatedSessions);
    setNewName('');
    onClose();
  };

  const handleDelete = async (sessionId: string) => {
    const token = Cookies.get('access_token');
    if (!token) return;

    try {
      await axios.delete(`${apiDomain}/flask/chat/delete_session`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          session_id: sessionId,
        },
      });
      setSessions((prevSessions) => prevSessions.filter(session => session.id !== sessionId));
      setActiveSessionId(null);
      if (sessions.length === 1) {
        setShowOptions(true);
      }
    } catch (error) {
      console.error('Error deleting session:', error);
    }
  };

  const sidebarWidth = useBreakpointValue({ base: '100%', md: '15%' });

  const todaySessions = sessions.filter(session =>
    session.messages && session.messages.some(message => dayjs(message.timestamp).isSame(dayjs(), 'day'))
  );

  if (loading) {
    return (
      <Flex minHeight="100vh" alignItems="center" justifyContent="center" bg="gray.100">
        <Spinner size="xl" />
      </Flex>
    );
  }

  const activeSession = activeSessionId ? sessions.find(session => session.id === activeSessionId) : null;
  const showOptionsScreen = !activeSession || activeSession.messages.filter(message => message.role !== 'system').length === 0;

  return (
    <Flex minHeight="100vh" direction="column" color="black" overflow="hidden">
      <Header />
      <Flex flex="1" direction={{ base: 'column', md: 'row' }} overflow="hidden">
        <MenuBar />
        <Box w={sidebarWidth} bg="gray.100" p="4" overflowY="auto" maxHeight="calc(100vh - 50px)" borderRight="1px solid #e2e8f0">
          <VStack align="start" spacing="4" w="100%">
            <Text fontSize="xl" fontWeight="bold">Chats</Text>
            <Button leftIcon={<AddIcon />} className="btn" variant="solid" size="sm" alignSelf="start" onClick={() => {
              createNewSession();
              setShowOptions(true);
            }}>
              New Chat
            </Button>
            <Divider />
            <Text fontSize="lg" fontWeight="bold">Today</Text>
            <VStack align="start" spacing="2" w="100%">
              {todaySessions.map((session) => (
                <HStack
                  key={session.id}
                  justify="space-between"
                  w="100%"
                  p="2"
                  borderRadius="md"
                  bg="gray.200"
                  _hover={{ bg: 'gray.300' }}
                  onClick={() => {
                    console.log('Switching to session ID:', session.id);
                    setActiveSessionId(session.id);
                    setShowOptions(false);
                  }}
                >
                  <Text>{session.name}</Text>
                  <Menu>
                    <MenuButton as={IconButton} icon={<EditIcon />} size="sm" variant="ghost" />
                    <MenuList>
                      <MenuItem onClick={() => { setRenameSessionId(session.id); onOpen(); }}>Rename</MenuItem>
                      <MenuItem onClick={() => handleDelete(session.id)}>Delete</MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>
              ))}
            </VStack>
          </VStack>
        </Box>
        <Flex flex="1" direction="column" overflow="hidden">
          {showOptionsScreen ? (
            <Flex flex="1" alignItems="center" justifyContent="center" direction="column" bg="gray.100">
              <Text fontSize="2xl" mb="4">Let's get started</Text>
              <Wrap spacing="4" justify="center">
                <WrapItem>
                  <Button variant="outline" colorScheme="black" onClick={() => handleOptionClick('Analyze our current expense reports and suggest areas where we could potentially reduce costs.')}>
                    Analyze our current expense reports and suggest areas where we could potentially reduce costs.
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button variant="outline" colorScheme="black" onClick={() => handleOptionClick('Summarize my financial statements')}>
                    Summarize my financial statements
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button variant="outline" colorScheme="black" onClick={() => handleOptionClick('Create forecasts and budget reports')}>
                    Create forecasts and budget reports
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button variant="outline" colorScheme="black" onClick={() => handleOptionClick('Evaluate our vendor contracts and suggest any opportunities for renegotiation to reduce costs')}>
                    Evaluate our vendor contracts and suggest any opportunities for renegotiation to reduce costs
                  </Button>
                </WrapItem>
              </Wrap>
            </Flex>
          ) : (
            <VStack flex="1" spacing="4" overflowY="auto" p="4" bg="gray.100">
              {activeSession?.messages
                .filter(message => message.role !== 'system')
                .map((message, index) => (
                  <HStack
                    key={index}
                    alignSelf={message.role === 'user' ? 'flex-end' : 'flex-start'}
                    bg={message.role === 'user' ? 'gray.200' : 'gray.400'}
                    p="2"
                    borderRadius="md"
                    maxW="80%"
                  >
                    {message.role === 'assistant' && <Avatar size="xs" name="Fincraft Bot" src={FincraftBot} />}
                    <Text whiteSpace="pre-wrap" ml={message.role === 'assistant' ? 2 : 0}>{message.content}</Text>
                  </HStack>
                ))}
            </VStack>
          )}
          <Box position="relative">
            <Box
              p="4"
              bg="gray.100"
              position="sticky"
              bottom="0"
              zIndex="1"
              boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
            >
              <HStack>
                <Input
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Type your message here..."
                  bg="gray.200"
                  flex="1"
                />
                <IconButton
                  icon={<AttachmentIcon />}
                  aria-label="Attach file"
                  onClick={() => document.getElementById('file-input')?.click()}  // Trigger file input click
                />
                <Button onClick={() => sendMessage(input)} className="btn">Send</Button>
              </HStack>
            </Box>
          </Box>
        </Flex>
      </Flex>

      {/* Rename Modal */}
      {isOpen && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0,0,0,0.5)"
          zIndex="1000"
        >
          <Box bg="white" p="4" borderRadius="md" boxShadow="md">
            <Text mb="2">Rename Chat</Text>
            <Input
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              placeholder="New chat name"
              mb="4"
            />
            <Button onClick={() => handleRename(renameSessionId as string)} className="btn" mr="2">Rename</Button>
            <Button onClick={onClose}>Cancel</Button>
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default ChatInterface;
