import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './tailwind.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from './auth/AuthContext';
import theme from './theme';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);

const domain = process.env.REACT_APP_AUTH0_DOMAIN!;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const onRedirectCallback = (appState: any) => {
  window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname);
};

root.render(
  <ChakraProvider theme={theme}>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthProvider>
        <App />
      </AuthProvider>
    </Auth0Provider>
  </ChakraProvider>
);