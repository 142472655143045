import React, { useState } from 'react';
import { Box, Button, Text, VStack, Select, Checkbox, CheckboxGroup, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Progress } from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const BusinessProfile = () => {
  const [step, setStep] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [industry, setIndustry] = useState('');
  const [annualRevenue, setAnnualRevenue] = useState(100000);
  const [financialGoals, setFinancialGoals] = useState<string[]>([]);
  const [quickbooksConnector, setQuickbooksConnector] = useState(false);
  const [hubspotConnector, setHubspotConnector] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [companyStage, setCompanyStage] = useState('');
  const navigate = useNavigate();

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  const handleNext = () => {
    if (step === 0 && !companyName) {
      setErrorMessage('Please enter your company name.');
      return;
    }
    if (step === 1 && !companySize) {
      setErrorMessage('Please select your company size.');
      return;
    }
    if (step === 2 && !industry) {
      setErrorMessage('Please select your industry.');
      return;
    }
    if (step === 3 && !annualRevenue) {
      setErrorMessage('Please select your annual revenue.');
      return;
    }
    setErrorMessage('');
    setStep(step + 1);
  };

  const handleBack = () => setStep(step - 1);

  const handleSubmit = async () => {
    try {
      await axios.post(`${apiDomain}/profile`, {
        companyName,
        companySize,
        industry,
        annualRevenue,
        financialGoals,
        quickbooksConnector,
        hubspotConnector,
      });
      handleNext(); // Go to the next step (Company Profile Created screen)
    } catch (error: any) {
      console.error('Error creating profile:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
      } else {
        console.error('Unknown error:', String(error));
      }
    }
  };

  const handleSkip = () => {
    navigate('/dashboard');
  };

  const handleConnector = () => {
    navigate('/connectors');
  };

  const handleInviteTeam = () => {
    navigate('/user-management');
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bg="gray.100">
      <Box bg="white" p="8" borderRadius="md" shadow="md" width="500px">
        {step > 0 && step < 5 && (
          <Progress value={(step / 5) * 100} size="xs" className="btn" mb="4" />
        )}
        {step === 0 && (
          <VStack spacing="4">
            <img src="../assets/fincraft-img-256.png" alt="Fincraft Logo" className="h-12 mb-4" />
            <Text fontSize="2xl" fontWeight="bold">Welcome to Fincraft</Text>
            <Text textAlign="center">We're excited to help you get started with your company profile. This will help us ensure you're getting the most out of Fincraft from day one.</Text>
            <input 
              type="text" 
              value={companyName} 
              onChange={(e) => setCompanyName(e.target.value)} 
              placeholder="Enter your company name" 
              className="border px-3 py-2 rounded w-full mb-4" 
              required 
            />
            <Button className='btn' onClick={handleNext}>Get Started</Button>
            <Button variant="link" onClick={handleSkip}>Skip</Button>
          </VStack>
        )}
        {step === 1 && (
          <VStack spacing="4" align="center">
            <Text color="purple.500">Step 1/4</Text>
            <Text fontSize="lg" fontWeight="bold">Tell us about your company</Text>
            <Text alignSelf="flex-start">Company Size</Text>
            <Select placeholder="Select your company size" value={companySize} onChange={(e) => setCompanySize(e.target.value)}>
              <option value="1-10">1-10</option>
              <option value="11-50">11-50</option>
              <option value="51-200">51-200</option>
              <option value="201-500">201-500</option>
              <option value="501+">501+</option>
            </Select>
            {errorMessage && <Text color="red.500">{errorMessage}</Text>}
            <Button className='btn' onClick={handleNext}>Next</Button>
            <Button variant="link" onClick={handleBack}>Back</Button>
          </VStack>
        )}
        {step === 2 && (
          <VStack spacing="4" align="center">
            <Text color="gray.500">Step 2/4</Text>
            <Text fontSize="lg" fontWeight="bold">What industry do you work in?</Text>
            <Text alignSelf="flex-start">Industry</Text>
            <Select placeholder="Search or type an industry" value={industry} onChange={(e) => setIndustry(e.target.value)}>
              <option value="tech">Tech</option>
              <option value="finance">Finance</option>
              <option value="healthcare">Healthcare</option>
              <option value="education">Education</option>
              <option value="other">Other</option>
            </Select>
            {errorMessage && <Text color="red.500">{errorMessage}</Text>}
            <Button className="btn" onClick={handleNext}>Next</Button>
            <Button variant="link" onClick={handleBack}>Back</Button>
          </VStack>
        )}
        {step === 3 && (
          <VStack spacing="4" align="center">
            <Text color="gray.500">Step 3/4</Text>
            <Text fontSize="lg" fontWeight="bold">What is your company's annual revenue?</Text>
            <Text alignSelf="flex-start">Annual Revenue</Text>
            <Slider min={0} max={20000000} step={100000} value={annualRevenue} onChange={(val) => setAnnualRevenue(val)}>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={6} fontSize="sm" children={`$${(annualRevenue / 1000000).toFixed(1)}M`} />
            </Slider>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Text>$0</Text>
              <Text>$20M+</Text>
            </Box>
            {errorMessage && <Text color="red.500">{errorMessage}</Text>}
            <Button className="btn" onClick={handleNext}>Next</Button>
            <Button variant="link" onClick={handleBack}>Back</Button>
          </VStack>
        )}
        {step === 4 && (
          <VStack spacing="4" align="center">
            <Text color="gray.500">Step 4/4</Text>
            <Text fontSize="lg" fontWeight="bold">Which stage is your company in?</Text>
            <Select placeholder="Select your company stage" value={companyStage} onChange={(e) => setCompanyStage(e.target.value)}>
              <option value="pre-seed">Pre-Seed</option>
              <option value="seed">Seed</option>
              <option value="series-a">Series A</option>
              <option value="series-b">Series B</option>
              <option value="series-c">Series C</option>
              <option value="bootstrap">Bootstrapped</option>
            </Select>
            {errorMessage && <Text color="red.500">{errorMessage}</Text>}
            <Button className="btn" onClick={handleSubmit}>Submit</Button>
            <Button variant="link" onClick={handleBack}>Back</Button>
          </VStack>
        )}
        {step === 5 && (
          <VStack spacing="4" align="center">
            <Text color="gray.500">Step 4/4</Text>
            <Text fontSize="lg" fontWeight="bold">What are your company's financial goals?</Text>
            <Text>This helps us understand your company's financial ambitions.</Text>
            <CheckboxGroup value={financialGoals} onChange={(val) => setFinancialGoals(val as string[])}>
              <VStack align="start" spacing="2">
                <Checkbox value="profitability">Achieve profitability (e.g., positive net income)</Checkbox>
                <Checkbox value="revenue_growth">Maximize revenue growth (e.g., 2x year-over-year)</Checkbox>
                <Checkbox value="cash_flow">Optimize cash flow (e.g., shorten collections cycle)</Checkbox>
                <Checkbox value="operating_costs">Minimize operating costs (e.g., reduce COGS)</Checkbox>
                <Checkbox value="gross_margins">Preserve or increase gross margins (e.g., maintain GMV)</Checkbox>
                <Checkbox value="market_expansion">Capitalize on market expansion (e.g., new vertical)</Checkbox>
              </VStack>
            </CheckboxGroup>
            {errorMessage && <Text color="red.500">{errorMessage}</Text>}
            <Button className="btn" onClick={handleSubmit}>Submit</Button>
            <Button variant="link" onClick={handleBack}>Back</Button>
          </VStack>
        )}
        {step === 6 && (
          <VStack spacing="4">
            <Text fontSize="2xl" fontWeight="bold">Company profile created!</Text>
            <Text textAlign="center">Your company profile has been created. Now you can create your first project, invite your team, and start building your AI models.</Text>
            <Button className="btn" onClick={handleConnector}>Create your first data connector</Button>
            <Button className="link" onClick={handleInviteTeam}>Invite your team</Button>
            <Button variant="link" onClick={handleSkip}>Skip</Button>
          </VStack>
        )}
      </Box>
    </Box>
  );
};

export default BusinessProfile;
