import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QuickBooksLogo from './assets/quickbooks-logo.jpg';
import GoogleAnalyticsLogo from './assets/google-analytics-logo.png';
import HubSpotLogo from './assets/hubspot-logo.png';
import SalesForceLogo from './assets/salesforce-logo.png';
import Menu from './Menu';
import Header from './Header';
import axios from 'axios';
import { useAuth } from './auth/AuthContext'; // Updated import

const Connectors = () => {
  const [error, setError] = useState<string | null>(null);
  const [connectedApps, setConnectedApps] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { authState, logout } = useAuth(); // Use AuthContext

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  useEffect(() => {
    if (!authState) {
      navigate('/login');
    }
  }, [authState, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get('status');
    const message = params.get('message');

    if (status === 'failure') {
      setError(message || 'An error occurred while connecting to a data source.');
    } else if (status === 'success') {
      navigate('/select-api');
    }
  }, [location, navigate]);

  useEffect(() => {
    const fetchConnectedApps = async () => {
      if (!authState) return; // Check for authState before making API call
      try {
        const response = await axios.get(`${apiDomain}/connectors/connected-apps`, {
          headers: { Authorization: `Bearer ${authState}` },
        });
        setConnectedApps(response.data.map((app: { name: string }) => app.name));
      } catch (error: unknown) {
        let errorMessage = 'Error fetching connected apps';
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        console.error('Error fetching connected apps:', errorMessage);
        setError(errorMessage);
      }
    };

    fetchConnectedApps();
  }, [apiDomain, authState]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleQuickBooksClick = () => {
    window.location.href = `${apiDomain}/quickbooks/connect`;
  };

  const handleGoogleAnalyticsClick = () => {
    const clientId = 'YOUR_GOOGLE_CLIENT_ID';
    const redirectUri = 'YOUR_REDIRECT_URI';
    const scope = 'https://www.googleapis.com/auth/analytics.readonly';
    const state = 'YOUR_STATE';

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}&access_type=offline`;

    window.location.href = authUrl;
  };

  const handleHubSpotClick = () => {
    window.location.href = `${apiDomain}/hubspot/connect`;
  };

  const handleSalesForceClick = () => {
    window.location.href = `${apiDomain}/salesforce/connect`;
  };

  const appLogos: { [key: string]: string } = {
    quickbooks: QuickBooksLogo,
    googleAnalytics: GoogleAnalyticsLogo,
    hubspot: HubSpotLogo,
    salesforce: SalesForceLogo,
  };

  const allApps = [
    { name: 'QuickBooks', handler: handleQuickBooksClick },
    { name: 'Google Analytics', handler: handleGoogleAnalyticsClick },
    { name: 'HubSpot', handler: handleHubSpotClick },
    { name: 'Salesforce', handler: handleSalesForceClick },
  ];

  const availableApps = allApps.filter(app => !connectedApps.includes(app.name.toLowerCase()));
  const connectedAppsList = allApps.filter(app => connectedApps.includes(app.name.toLowerCase()));

  return (
    <div className="flex min-h-screen bg-gray-100">
      <Menu />
      <main className="flex-1">
        <Header />
        <div className="p-8">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold">Connectors</h1>
          </div>
          <div className="bg-white shadow rounded p-4">
            <p className="mb-4">Manage your connected data sources.</p>
            <h2 className="text-xl font-semibold mb-4">Connected Apps</h2>
            {connectedAppsList.length > 0 ? (
              <div className="grid grid-cols-3 gap-4 mb-8">
                {connectedAppsList.map(app => (
                  <div key={app.name} className="flex items-center border rounded-lg p-4 relative">
                    <img src={appLogos[app.name.toLowerCase()]} alt={`${app.name} Logo`} className="h-8 w-8 mr-4" />
                    <span className="text-lg font-medium capitalize">{app.name}</span>
                    <button
                      className="absolute top-2 right-2 bg-white text-blue-500 rounded-full p-1 border border-blue-500"
                    >
                      ...
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <p>No connected apps</p>
            )}
            <h2 className="text-xl font-semibold mb-4">Available Apps</h2>
            <div className="grid grid-cols-3 gap-4">
              {availableApps.map(app => (
                <div key={app.name} className="flex items-center border rounded-lg p-4 relative mb-4">
                  <img src={appLogos[app.name.toLowerCase()]} alt={`${app.name} Logo`} className="h-8 w-8 mr-4" />
                  <span className="text-lg font-medium">{app.name}</span>
                  <button
                    onClick={app.handler}
                    className="absolute top-2 right-2 bg-white text-blue-500 rounded-full p-1 border border-blue-500"
                  >
                    +
                  </button>
                </div>
              ))}
            </div>
          </div>
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
              <span className="block sm:inline">{error}</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setError(null)}>
                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 5.652a1 1 0 0 0-1.414 0L10 8.586 7.066 5.652a1 1 0 1 0 1.414 1.414L8.586 10l-2.934 2.934a1 1 0 1 0 1.414 1.414L10 11.414l2.934-2.934a1 1 0 0 0 0-1.414z" />
                </svg>
              </span>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default Connectors;
